import _defineProperty from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(function (theme) {
  var _svg;

  return {
    carouselRoot: {
      position: 'relative',
      '& .slick-disabled': {
        opacity: '.25'
      },
      '& .slick-next, .slick-prev': {
        zIndex: 1
      }
    },
    arrow: {
      '& svg': (_svg = {
        position: 'relative',
        width: 50,
        height: 50,
        color: '#000',
        top: -55,
        borderRadius: '50%',
        background: '#ffffff75',
        boxShadow: '0 1px 2px rgba(0, 0, 0, .2)'
      }, _defineProperty(_svg, "color", '#414042'), _defineProperty(_svg, "background", '#ffff'), _defineProperty(_svg, "padding", 5), _svg)
    },
    arrow1: {
      '& svg': {
        left: -10
      }
    },
    arrow2: {
      '& svg': {
        right: -10
      }
    }
  };
});